<template>
  <AppHeader
    display-back-button
    :has-error="hasError"
    @next="nextStep"
    @back="back"
  />
  <v-footer
    :elevation="4"
    app
  >
  
  <DownloadBtn v-if="document.allowDownload" :document="document" with-top-padding />
  <v-checkbox
    v-model="checked"
    :class="{ 'has-error': hasError }"
    :required="isRequired"
    :label="$translate(props.document.confirmationCaption)"
    hide-details
    :value="true"
  />
  </v-footer>
</template>
<script setup lang="ts">
import type { ScannerDocument } from '@einfachgast/shared';
import { computed, ref, type PropType } from 'vue';
import AppHeader from '@/components/AppHeader.vue';
import DownloadBtn from '@/components/dynamic-form/renderers/document/DownloadBtn.vue';

const emit = defineEmits<{
  (e: 'confirm', value: boolean): void,
  (e: 'back'): void}
>();

const props = defineProps({
  document: {
    type: Object as PropType<ScannerDocument>,
    required: true,
    default: () => ({} as ScannerDocument),
  },
});
const hasError = ref(false);

const back = () => {
  emit('back');
};

const isRequired = computed(() => {
  return props.document.confirmationType === 'checkbox';
});

const checked = ref(false);
const nextStep = () => {
  if (!validate()) {
    hasError.value = true;
    return;
  }
  emit('confirm', checked.value);
};

const validate = () => {
  if(isRequired.value && checked.value !== true){
    return false;
  }
  return true;
};
</script>
<style lang="scss">
.has-error label {
  color: red;
}
</style>
