<template>
  <AppHeader
    display-back-button
    @next="nextStep"
    @back="previousStep"
  />
  <DownloadBtn v-if="document.allowDownload" :document="document" />
 </template>

<script setup lang="ts">
import type { ScannerDocument } from '@einfachgast/shared';
import type { PropType } from 'vue';
import AppHeader from '@/components/AppHeader.vue';
import DownloadBtn from '@/components/dynamic-form/renderers/document/DownloadBtn.vue';

const emit = defineEmits<{
  (e: 'confirm'): void,
  (e: 'back'): void}
>();

defineProps({
  document: {
    type: Object as PropType<ScannerDocument>,
    required: true,
    default: () => ({} as ScannerDocument),
  },
});
const nextStep = () => {
  emit('confirm');
};

const previousStep = () => {
  emit('back');
};
</script>