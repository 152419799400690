<template>
  <v-btn
    variant="flat"
    class="download-btn"
    :class="withTopPadding ? 'with-top-padding' : ''"
    data-qa="download-document-button"
    @click="downloadDocument"
  >
    <v-icon class="mr-1" :icon="mdiDownload" /> {{ $translate('download') }}
  </v-btn>
</template>
<script setup lang="ts">
import type { ScannerDocument } from '@einfachgast/shared';
import { type PropType } from 'vue';
import { mdiDownload } from '@mdi/js';
const i18nStore = useI18nStore();
import { useI18nStore } from '@/store/i18n';
import { translate } from '@/plugins/translator';

const props = defineProps({
  document: {
    type: Object as PropType<ScannerDocument>,
    required: true,
    default: () => ({} as ScannerDocument),
  },
  withTopPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const downloadDocument = async () => {
  try {
    // Fetch the PDF data as a Blob
    const response = await fetch(translate(props.document.urls));
    let blob = await response.blob();
    blob = blob.slice(0, blob.size, 'application/octet-stream');
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = `${props.document.name[i18nStore.selectedLanguage]}.pdf`;

    // Append the link to the document, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
	  console.error('Error while downloading PDF:', error);
  }
};

</script>
<style lang="scss">
.v-btn.download-btn {
  position: fixed;
  right: 20px;
  color: #374151;
  font-size: 16px;
  font-weight: normal !important;
  background: #E5E7EB;
  border: none;
  bottom: 20px;
  
}
.download-btn.with-top-padding {
  bottom: unset;
  top: -55px;
}
</style>
